import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from './404.module.scss'

const NotFoundPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title="404 Not Found" description="The page is not found." />
    <main role="main" className={styles.main}>
      <div className={styles.wrapper}>
        <section className={styles.notfound}>
          <h1>404 Page Not Found</h1>
          <p>The page your are looking for is not found.</p>
          <p>
            <Link to="/">Back to Top</Link>
          </p>
        </section>
      </div>
    </main>
  </Layout>
)

export default NotFoundPage
